import React from 'react'
import Header from '../../components/Header'
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from '../../components/Footer';
import performanceIcon from "../../assets/images/icons/performance.png";

const Performance = () => {
    const modifiedItem = {
        index: 1,
        icon: performanceIcon,
        label: "Performance",
        link: "/employe/performance",
    };
  return (
    <>
    
    <Header title="Performance" arrowIcon={arrowIcon} />
    <div className="ContentContainer">
    </div>
    <Footer modifiedItem={modifiedItem} />
    </>
  )
}

export default Performance
import { createContext, useContext, useState, useEffect } from "react";
import ProfileIcon from "../assets/images/icons/defaultimg.png";
import { apiCall } from "../services/apiservices";

export const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);

export const UIProvider = ({ children }) => {
  const [home, setHome] = useState('/home');
  const [SelectImage, setSelectedImage] = useState(ProfileIcon);
  const [totalTarget, setTotalTarget] = useState();
  const [achievedTarget, setAchievedTarget] = useState();
  const [pendingTarget, setPendingTarget] = useState();
  const [loading, setloading] = useState(false);
  const [chatUserProfile, setChatUserProfile] = useState();
  // const [contextOnlineStatus, setContextOnlineStatus] = useState('Offline');
  // const [contextTypingStatus, setContextTypingStatus] = useState();
  const [typingStatus, setTypingStatus] = useState('');
  const [onlineStatus, setOnlineStatus] = useState('Offline');

  const fetchProfileData = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user-cred'));
      if(user && user?.token){
        let response = await apiCall({ endpoint: "auth/profiledata", method: 'GET' });
        if (response && response.data && response.data.length > 0) {
          setSelectedImage(response.data[0].customer_profile || ProfileIcon);
        } else {
          setSelectedImage(ProfileIcon);
        }
      }
    } catch (error) {
      setSelectedImage(ProfileIcon);
    } 
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const value = {
    home,
    setHome,
    SelectImage,
    setSelectedImage,
    totalTarget,
    setTotalTarget,
    achievedTarget,
    setAchievedTarget,
    pendingTarget,
    setPendingTarget,
    loading,
    setloading,
    fetchProfileData,  // Exposing fetchProfileData here
    chatUserProfile,
    setChatUserProfile,
    typingStatus, 
    setTypingStatus,
    onlineStatus,
    setOnlineStatus
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};

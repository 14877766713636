import React from 'react'
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import Profile1Icon from "../../assets/images/icons/profile1.png";
import Header from '../../components/Header';



const Recharge = () => {
  const modifiedItem = {
    index: 1,
    icon: Profile1Icon,
    label: "Recharge",
    link: "/recharge",
  };
  return (
    <>
    <Header title="Recharge" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        
      </div>
      
      <Footer modifiedItem={modifiedItem} />
    </>
  )
}

export default Recharge
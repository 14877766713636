import React from 'react';
import "../../assets/css/employe/employetarget.css";
import Header from '../../components/Header';
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from '../../components/Footer';
import targetIcon from '../../assets/images/icons/target.png';
// import EmployeTabs from '../../components/EmployeTabs';
import EmployeGraph from '../../components/EmployeGraph';
// import EmployeGraph from '../../components/EmployeGraph';




const Graph = () => {
    const modifiedItem = {
        index: 1,
        icon: targetIcon,
        label: "Graph",
        link: "/employe/graph",
      };
    
  return (
    <>
                <Header title="Graph" arrowIcon={arrowIcon} />
            <div className="ContentContainer">

             <EmployeGraph/>
            </div>
            <Footer modifiedItem={modifiedItem} />
            </>
  )
}

export default Graph;
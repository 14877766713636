import React, { useState, useRef, useEffect } from 'react';
import "../../assets/css/customer/bot.css";
// import Profile1Icon from "../../assets/images/icons/profile1.png"; // Update the path if needed
import arrowIcon from "../../assets/images/icons/arrow.png"; // Back arrow icon
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
// import SendIcon from '@mui/icons-material/Send';
import SendIcon from "../../assets/images/icons/send.png"; // Back arrow icon
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; 
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone'; // Import double check icon
import Header from '../../components/Header';

const Bot = () => {
  // const modifiedItem = {
  //   index: 1,
  //   icon: Profile1Icon,
  //   label: "Profile",
  //   link: "/profile",
  // };

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [image, setImage] = useState(null);
  const chatWindowRef = useRef(null); // Create a ref for the chat window

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (input.trim() || image) {
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      const newMessage = {
        text: input,
        image: image ? URL.createObjectURL(image) : null,
        sender: 'user',
        timestamp,
        read: false,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput('');
      setImage(null); // Reset image after sending

      // Simulate bot response
      setTimeout(() => {
        const botResponse = generateBotResponse(input, image);
        setMessages((prevMessages) => [...prevMessages, botResponse]);
      }, 1000);
    }
  };

  const generateBotResponse = (input, image) => {
    const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    let textResponse;

    if (input) {
      textResponse = `You said: "${input}".`;
    } else if (image) {
      textResponse = `I received your image.`;
    } else {
      textResponse = `How can I help you?`;
    }

    return {
      text: textResponse,
      sender: 'bot',
      timestamp,
      read: true,
    };
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleVoiceRecord = () => {
    alert('Voice recording functionality to be implemented!');
  };


  const Message = ({ text, image, sender, timestamp, read }) => (
    <div className={sender === 'user' ? 'BotUserMessage' : 'BotMessage'}>
      {image && <img src={image} alt="Uploaded" className='BotImageMessage' />}
      {text && <div className='BotMessageText Word_breack'>{text}</div>}
      <div className='BotMessageFooter'>
        <span className='BotTimestamp'>{timestamp}</span>
        {sender === 'user' && read ? (
          <div className='BotCheckIcons'>
            <DoneAllIcon className='BotCheckIcon' />
            <CheckCircleTwoToneIcon className='BotCheckIcon' />
          </div>
        ) : sender === 'user' && !read ? (
          <DoneAllIcon className='BotCheckIcons' />
        ) : null}
      </div>
    </div>
  );

  // Effect to auto-scroll to the bottom when messages change
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
     <Header title="Ai Bot" arrowIcon={arrowIcon} />
      <div className="ContentContainer BotChatApp" >
        <div ref={chatWindowRef} className='BotChatWindow'>
          {messages.map((msg, index) => (
            <Message 
              key={index} 
              text={msg.text} 
              image={msg.image} 
              sender={msg.sender} 
              timestamp={msg.timestamp} 
              read={msg.read} 
            />
          ))}
        </div>
        <form onSubmit={handleSendMessage} className='BotMessageInput'>
          <label htmlFor="file-upload" className='BotUploadLabel'>
            <AttachFileIcon className='BotIcon' />
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            className='BotFileInput'
            onChange={handleUpload}
          />
          <MicIcon 
            className='BotIcon' 
            onClick={handleVoiceRecord} 
          />
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className='BotInput'
          />
          <button type="submit" className='BotSendButton'>
            {/* <SendIcon className='BotSendIcon' /> */}

            <img src={SendIcon} alt="icon"  className='BotSendIcon' />
          </button>
        </form>
      </div>
    </>
  );
};


export default Bot;

// src/components/AuthGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const EmployeeGuard = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user-cred'));
  const userType = user?.user?.user_role
  if (!user || !user.token) {
    return <Navigate to="/login" replace />;
  }

  if(userType !== 'employee'){
    if(userType === 'customer'){
        return <Navigate to="/home" replace />;
    }else if(userType === 'admin'){
        return <Navigate to="/admin/home" replace />;
    }else{
        return <Navigate to="/" replace/>
    }
  }

  return children;
};

export default EmployeeGuard;

import React, { useState } from 'react';
import "../../assets/css/customer/signup.css";
import Logo from '../../assets/images/logo.png';
import seePasswordIcon from '../../assets/images/icons/eye.png';
import hidePasswordIcon from '../../assets/images/icons/eye1.png';
import { Link, useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [formData, setFormData] = useState({
        name: '',
        userId: '',
        password: '',
        confirmPassword: ''
    });
    const [open, setOpen] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.userId) newErrors.userId = 'User ID is required';
        if (!formData.password) newErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            navigate('/process',{state: formData});
        }
    };

    return (
        <>
            <div className="SignUpContain">
                <div className="SignUpLogo">
                    <img src={Logo} alt="img" />
                </div>
                <form onSubmit={handleSubmit} className="SignUpCard">
                    <div className="SignUpTitle">
                        <h4>Sign Up</h4>
                        <p>Welcome To Manthan App.</p>
                        <p>Manthan support team is just a tap away!</p>
                    </div>
                    <div className="SignUpGroup">
                        <label htmlFor="name">Your Name <span className="RequieredStar">*</span></label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        {errors.name && <span className="error">{errors.name}</span>}
                    </div>
                    <div className="SignUpGroup">
                        <label htmlFor="userId">User ID <span className="RequieredStar">*</span></label>
                        <input
                            type="text"
                            name="userId"
                            placeholder="Enter User ID"
                            value={formData.userId}
                            onChange={handleInputChange}
                        />
                        {errors.userId && <span className="error">{errors.userId}</span>}
                    </div>
                    <div className="SignUpGroup">
                        <label htmlFor="password">Password <span className="RequieredStar">*</span></label>
                        <div className="PasswrdInpt">
                            <input
                                type={open ? 'text' : 'password'}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                            <button type="button" onClick={() => setOpen(!open)}>
                                <img src={open ? hidePasswordIcon : seePasswordIcon} alt="icon" />
                            </button>
                        </div>
                        {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                    <div className="SignUpGroup">
                        <label htmlFor="confirmPassword">Confirm Password <span className="RequieredStar">*</span></label>
                        <div className="PasswrdInpt">
                            <input
                                type={confirmPasswordVisible ? 'text' : 'password'}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                            />
                            <button type="button" onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}>
                                <img src={confirmPasswordVisible ? hidePasswordIcon : seePasswordIcon} alt="icon" />
                            </button>
                        </div>
                        {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                    </div>
                    <div className="SignUpGroup SignUpBtn">
                        <button type="submit">Proceed</button>
                    </div>
                    <div className="SignUpDivider"><span>OR</span></div>
                    <div className="SignUpGroup signUp">
                        <p>Already have an account? <Link to='/'>Login</Link></p>
                    </div>
                </form>
            </div>
            <div className="SignUpFooter">
            <p>
          POWERED BY: <span>MANTHAN IT SOLUTIONS</span>
        </p>
            </div>
        </>
    );
};

export default SignUp;

import React, { useEffect, useRef } from 'react';
import "../../assets/css/employe/employetarget.css";
import Header from '../../components/Header';
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from '../../components/Footer';
import targetIcon from '../../assets/images/icons/target.png';
import EmployeTabs from '../../components/EmployeTabs';
// import EmployeGraph from '../../components/EmployeGraph';

const EmployeTarget = () => {
  const modifiedItem = {
    index: 1,
    icon: targetIcon,
    label: "Target",
    link: "/employe/target",
  };


    return (
        <>
            <Header title="Target" arrowIcon={arrowIcon} />
            <div className="ContentContainer">

              <div className="TabsContain">
                <EmployeTabs/>

              </div>

                {/* <EmployeGraph/> */}
            </div>
            <Footer modifiedItem={modifiedItem} />
        </>
    );
};

export default EmployeTarget;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../assets/css/customer/chatPreview.css';
import Header from '../../components/Header';
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import chatIcon from "../../assets/images/icons/chat.png";
import employesIcon from "../../assets/images/icons/employes.png";
import SearchIcon from "@mui/icons-material/Search";
import { apiCall } from "../../services/apiservices";
import { Avatar } from "@mui/material";
import SetLoadingScreen from "../../components/SetLoader";
import { useUIContext } from "../../context";

const ChatPreview = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  const [chatBlockedMessage, setChatBlockedMessage] = useState("");
  const localStorageItems = localStorage.getItem("user-cred");
  const parsedItems = JSON.parse(localStorageItems);
  const senderId = parsedItems.user.customer_id;
  const [loading, setLoading] = useState(false);
  const {chatUserProfile, setChatUserProfile} = useUIContext();

  // Fetch chat data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const endpoint = `chat/getChatPreview`;
        const response = await apiCall({ endpoint: endpoint, method: "GET" });
        if (response.success && response.data) {
          const formattedData = response.data.map((user, index) => ({            
            id: user.customer_id || index + 1,
            name: user.customer_name,
            sent_date: user.sent_date,
            message: user.message || user.media_type,            
            userProfile: user.customer_profile,
            unreadCount: user.unread_count
          }));
          setChatUserProfile(formattedData);
          setEmployees(formattedData);
        } else {
          setChatBlockedMessage(response.message);
        }
      } catch (error) {
        console.log("Error on fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setChatUserProfile]);

  const handleEmployeeClick = (id, name, userProfile) => {
    navigate(`/employe/chat/${id}`, { state: { employeeName: name, id, userProfile} });
  };

  // Filter employee data
  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SetLoadingScreen loading={loading} />
      <Header title="Chat" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <SearchIcon className="search-icon" />
        </div>
        <ul className="employee-list">
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee) => (
              <li
                key={employee.id}
                onClick={() => handleEmployeeClick(employee.id, employee.name, employee.userProfile)}
                className="employee-item"
              >
                {employee.userProfile ? (
                  <div className="Chatprofile">
                    <img src={employee.userProfile} alt="User Profile" />
                  </div>
                ) : (
                  <Avatar>{employee.name.charAt(0)}</Avatar>
                )}
                <div className="employee-info employee-info_w">
                  <div className="employee-name emp_name">
                    <span>{employee.name}</span>
                    <span className="sent_date_message">{employee.sent_date}</span>
                  </div>
                  <div className="employee-last-message emp_unread">
                    <span>{employee.message.length > 15 ? employee.message.slice(0, 25) + '...' : employee.message}</span>
                    {employee.unreadCount !== 0 ? <span className="unRead_msg">{employee.unreadCount} New Messages</span> : ''}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="blocked-message">{chatBlockedMessage}</div>
          )}
        </ul>
      </div>
      <Footer
        modifiedItem={{
          index: 1,
          icon: employesIcon,
          label: "Contacts",
          link: "/employe/chat",
        }}
      />
    </>
  );
};

export default ChatPreview;

import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/admin/followup.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import callNowIcon from "../../assets/images/icons/callnow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import { apiCall } from "../../services/apiservices";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CustomSnackbar from '../../components/snackBar';
import { ToastContainer, toast } from "react-toastify";


const Followup = () => {
  const { userTicketId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, cDate, category, cRemarks, ticketStatus } = location.state || {};
  
  const [ticketId, setTicketId] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [issueType, setIssueType] = useState('');
  const [customerRemarks, setCustomerRemarks] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [status, setStatus] = useState('');
  const [newStatus, setNewStatus] = useState('');
  const [mobile, setMobile] = useState('');
  const [followup, setFollowup] = useState('');
  const [followUpRemark, setFollowUpRemark] = useState("");
  const [submitModal, setSubmitModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [newStatusForTicket, setNewStatusForTicket] = useState('');

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  function closeModalOnSubmit() {
    setSubmitModal(false);
  }

  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "Followup",
    link: `/admin/followup/${ticketId}`,
  };

  useEffect(() => {
    setTicketId(id);
    setCreateDate(cDate);
    setIssueType(category);
    setCustomerRemarks(cRemarks);
    setStatus(ticketStatus);
    setNewStatus(ticketStatus);
  }, [id, cDate, category,cRemarks, ticketStatus]);

  useEffect(() => {
    const fetchData = async (userTicketId) => {
      const endpoint = `admin/getIndividualTicketData/${userTicketId}`;

      try {
        const res = await apiCall({ endpoint, method: 'GET' });
        if (res.success) {
          setFollowup(res.data.userName);
          setMobile(res.data.mobile);
          setCustomerId(res.data.customerId);
          setCustomerName(res.data.customerName)
        }
      } catch (error) {
        console.log('failed to hit API', error);
      }
    };
    fetchData(userTicketId);
  }, [userTicketId]);

  const handleStatusChange = (event) => {
    setNewStatus(event.target.value);
  };


  const handleSaveTicket = async () => {
    if(newStatus === 'Pending'){
      return toast.error('Status can only be In-Process and Resolved');
    }
    try {
      const payload = {
        ticketId,
        status: newStatus,
        followUpRemark,
        issueType,
        customerRemarks,
      };

      const res = await apiCall({ endpoint: 'admin/adminPostTicketDataUpdate', method: 'POST', payload });
      if (res.success) {
        setNewStatusForTicket(res.data);
        setSubmitModal(true);
      } else {
        showSnackbar('Internal Server Error', 'error');
      }
    } catch (error) {
      console.log('Error posting data', error);
    }
  };
    return (
      <>
      <Header title="Followup" arrowIcon={arrowIcon} />
        <div className="ContentContainer">
  
          <div className="FollowUpHeader mt-3 d-flex justify-content-between">
              <h4 >Ticket ID : <span>{ticketId}</span> </h4>
              
              <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
          </div>

  
          <div className="TrackDetails RequestDetails">
            <div className="d-flex justify-content-between Flexwrp320">
            <div className="TicketDate TrackMessageCard">
              <h4>Ticket Date</h4>
              <p>{createDate}</p>
            </div>
            <div className="TrackMessageCard Status">
              <h4>Status</h4>
              <select name="status" id="status" value={newStatus} onChange={handleStatusChange}>
                {status === 'In-Process' ? (
                  <>
                    <option value="In-Process">In-Process</option>
                    <option value="Resolved">Resolved</option>
                  </>
                ): (
                  <>
                    <option value="Pending" disabled>Pending</option>
                    <option value="In-Process">In-Process</option>
                    <option value="Resolved">Resolved</option>
                  </>
                )}
              </select>
            </div>
            </div>
 
              <div className="row gy-3 pt-3">
                  <div className="col-7">
                      <div className="TrackMessageCard">
                        <h4>Customer Name :</h4>
                        <p>{customerName}</p>
                      </div>
                  </div>
                  <div className="col-5 text-end">
                      <div className="TrackMessageCard">
                        <h4>Customer ID :</h4>
                        <p>{customerId}</p>
                      </div>
                  </div>
                  <div className="col-7">
                      <div className="TrackMessageCard">
                        <h4>Category :</h4>
                        <p>{issueType}</p>
                      </div>
                  </div>
                  <div className="col-5 text-end">
                      <div className="TrackMessageCard">
                        <h4>Mobile :</h4>
                        <p>{mobile}</p>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4>Customer Remarks : </h4>
                          <textarea name="comment" id="comment" value={customerRemarks} readOnly></textarea>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4>Followup Remarks : </h4>
                          <textarea name="comment1" id="comment1" onChange={(e) => setFollowUpRemark(e.target.value)}></textarea>
                      </div>
                  </div>
                  
                    <div className="col-12">
                    <div className="TrackMessageCard">
                      <h4>Followed By : <span>{followup}</span></h4>
                    </div>
                    </div>
                  
                  <div className="col-12">
                      <div className="TrackMessageCard text-center FollowupBtnContain">
                      <Link to={`/admin/followup/history/${ticketId}`}><button className="RequestModalBtn HistoryBtn" type="submit">History </button></Link>
                      <a href="tel:18001370808" target="_blank" rel="noreferrer"><button className="RequestModalBtn CallNowBtn" type="submit"><span><img src={callNowIcon} alt="icon" /></span> Call  </button></a>
                      <button className="RequestModalBtn" type="submit" onClick={handleSaveTicket}>Save </button>
                    </div>
                  </div>
              </div>
          </div>
            <div className={`SubmitModalContain ${submitModal ? '' : 'd-none'}`}>
            <div className="SubmitModal">
                <TaskAltIcon className='SubmitIConCheck'/>
                <p>Ticket has been {newStatusForTicket}.</p>
                <button onClick={() => {closeModalOnSubmit();
                  navigate(`/admin/${newStatus}`);
                }}>OK</button>
            </div>
            </div>
        </div>
        <ToastContainer />

        <Footer modifiedItem={modifiedItem} />
          <CustomSnackbar 
            open={snackbarOpen} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage} 
            severity={snackbarSeverity} 
          />
      </>
    );
};

export default Followup;
import React, { useEffect, useRef } from 'react';
import "../assets/css/employe/employetarget.css";
import "../assets/css/components/employegraph.css";
import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useUIContext } from '../context';

Chart.register(ArcElement, Tooltip, Legend, DoughnutController, ChartDataLabels);

const EmployeGraph = () => {
    const chartRef = useRef(null);
    const {achievedTarget,pendingTarget} = useUIContext();

    useEffect(() => {
        // Update targets inside useEffect to avoid updating during render


        // Create the chart after state updates
        const ctx = chartRef.current.getContext('2d');
        const chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: ['Achieved', 'Pending'],
                datasets: [{
                    data: [achievedTarget, pendingTarget],
                    backgroundColor: ['#36A2EB', '#FF6384'],
                    hoverOffset: 4,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 10,
                            padding: 10,
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                return `${tooltipItem.label}: ${tooltipItem.raw}`;
                            },
                        },
                    },
                    datalabels: {
                        color: '#fff',
                        formatter: (value, ctx) => {
                            let sum = ctx.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
                            let percentage = ((value / sum) * 100).toFixed(2) + '%';
                            return percentage;
                        },
                        anchor: 'center',
                        align: 'center',
                    },
                },
            },
        });

        return () => {
            chart.destroy();
        };
    }, []);

    return (
        <>
            <h4 className="GraphTitle">Monthly Target</h4>
            <div className="d-flex justify-content-center">
                <div className="GraphContainer">
                    <canvas ref={chartRef}></canvas>
                </div>
            </div>
        </>
    );
}

export default EmployeGraph;

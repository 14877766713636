import React, { useEffect, useState } from "react";
import "../../assets/css/employe/chatlist.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import employesIcon from "../../assets/images/icons/employes.png";
import { Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { apiCall } from "../../services/apiservices";
import { useUIContext } from "../../context";

const ChatList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [employees, setEmployees] = useState([]);
  
  // Adjusting for useUIContext to return an object
  const { chatUserProfile, setChatUserProfile } = useUIContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `chat/getChatUser`;
        const response = await apiCall({ endpoint: endpoint, method: 'GET' });
        
        if (response.success && response.data) {
          const formattedData = response.data.map((user, index) => ({
            id: user.customer_id || index + 1,
            name: user.customer_name,
            userProfile: user.customer_profile
          }));
          setChatUserProfile(formattedData);
          setEmployees(formattedData);
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [setChatUserProfile]); // Add setChatUserProfile as dependency

  const handleEmployeeClick = (id, name) => {
    navigate(`/employe/chat/${id}`, { state: { employeeName: name, id } });
  };

  // Filter employees based on the search term
  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const getLastMessage = (id) => {
  //   const storedMessages = JSON.parse(localStorage.getItem('messages')) || {};
  //   return storedMessages[id] ? storedMessages[id][storedMessages[id].length - 1]?.text : 'No messages yet.';
  // };

  return (
    <>
      <Header title="Chat List" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <SearchIcon className="search-icon" />
        </div>
        <ul className="employee-list">
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee) => (
              <li
                key={employee.id}
                onClick={() => handleEmployeeClick(employee.id, employee.name)}
                className="employee-item"
              >
                {employee.userProfile ? (
                  <div className="Chatprofile">
                    <img src={employee.userProfile} alt="User Profile" />
                  </div>
                ) : (
                  <Avatar>{employee.name.charAt(0)}</Avatar>
                )}
                
                <div className="employee-info">
                  <div className="employee-name">{employee.name}</div>
                  {/* <div className="employee-last-message">{getLastMessage(employee.id)}</div> */}
                </div>
              </li>
            ))
          ) : (
            <div className="blocked-message">Your chat has been blocked</div>
          )}
        </ul>
      </div>
      <Footer modifiedItem={{ index: 1, icon: employesIcon, label: "Chat List", link: "/employe/chat" }} />
    </>
  );
};

export default ChatList;

import React, { useState, useEffect } from 'react';
import "../../assets/css/customer/helpsupport.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Accordion from "../../components/Accordians";
import Footer from "../../components/Footer";
import helpSupportIcon from "../../assets/images/icons/help_support.png";
import ticketIcon from "../../assets/images/icons/ticket.png";
import Header from "../../components/Header";
import DashboardCards from "../../components/DashboardCard";
import { apiCall } from "../../services/apiservices";
import { useUIContext } from '../../context';
import SetLoadingScreen from "../../components/SetLoader";


const data = [
  {
    title: "Technical Support",
    text: false, 
    children: [
      {
        title: "SMS Support",
        text: false,
        children: [
          {
            title: "SMS Delivery Issue",
            text: true,
          }, 
          {
            title: "API Related Issue",
            text: true,
          }, 
          { title: "MIS Report Issue", 
            text: true,
         },
        ],
      },
      { title: "WhatsApp Support", 
        text: false,
        children: [
          {
            title: "WhatsApp API Issue",
            text: true,
          }, 
          { title: "WhatsApp Delivery Issue", 
            text: true,
         },
         { title: "MIS Report Issue", 
           text: true,
        },
        ],
     }, 
      { title: "OBD / Voice Call", 
        text: false,
        children: [
          {
            title: "Delivery Issue",
            text: true,
          }, 
          { title: "OBD Approvel Issue", 
            text: true,
         },
         { title: "MIS Report Issue", 
           text: true,
        },
        ],
     }, 
      { title: "RCS Support", 
        text: false,
        children: [
          {
            title: "RCS API Issue",
            text: true,
          }, 
          { title: "RCS Delivery Issue", 
            text: true,
         },
         { title: "MIS Report Issue", 
           text: true,
        },
        ], }, 
    ],
  },
  {
    title: "Customer Support",
    text: false,
    children: [
      {
        title: "Regarding DLT",
        text: false,
        children: [
          { title: "Related SMS Template", text: true }, 
          { title: "Related SMS Header / Sender ID", text: true }
        ],
      }, 
      {
        title: "Regarding Invoice",
        text: false,
        children: [
          { title: "Invoice Not Recieved", text: true }, 
        ],
      }, 
      { title: "Regarding WhatsApp", 
        text: false,
        children: [
            { title: "Related WhatsApp Template", text: true }, 
            { title: "Related WhatsApp Limit", text: true }, 
          ],
     },
    ],
  },
  {
    title: "DND Support",
    text: false,
    children: [
      {
        title: "DND API Issue",
        text: true,
      }, 
      {
        title: "DND Report Issue",
        text: true,
      }
    ],
  },
  {
    title: "Auto Dailer Support",
    text: false,
    children: [
      {
        title: "Call Not Connected",
        text: true,
      }, 
      {
        title: "Portal Not Working",
        text: true,
      }
    ],
  },
  {
    title: "Others",
    text: true,
  },
];

const HelpSupport = () => {

  const [totalCount,setTotalCount] = useState(0);
  const [openCount,setOpenCount] = useState(0);
  const [closeCount,setCloseCount] = useState(0);
  const {loading, setloading} = useUIContext();

  useEffect(() => {
  
    const getCountForTicketDashboard = async () =>{
    
      // const token = localStorage.getItem('token');
      // const parsedToken = jwtDecode(token);
      // const customer_id = parsedToken.customerID;
      // const payLoaData = {'customer_id': customer_id};
      setloading(true);
      try{
        const endpoint = `getTicketDashboardDataOnLoad`

        const response = await apiCall({ endpoint: endpoint, method: 'GET' });
        // let response = ''
    
        if(response){
          setloading(false);
          setTotalCount(response.data.totalCount);
          setOpenCount(response.data.openCount);
          setCloseCount(response.data.closeCount);
        }
    
      }catch(error){
        setloading(false);
        console.log('Error while fetching TO-DO data', error);
      }
    }

    getCountForTicketDashboard();

  }, []);

  const modifiedItem = {
    index: 1,
    icon: helpSupportIcon,
    label: "Help & Support",
    link: "/helpsupport",
  };
  const ticketData = [
    { count: `${totalCount}`, label: 'Total', className: '' },
    { count: `${openCount}`, label: 'Pending', className: 'pending' },
    { count: `${closeCount}`, label: 'Resolved', className: 'resolve' },
  ];
  return (
    <>
      <SetLoadingScreen loading={loading}/>
      <Header title="Help & Support" arrowIcon={arrowIcon} />

      <div className="ContentContainer">

      <DashboardCards title="Tickets Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} />
      
      <div className="HelpSupportContain">
      <Accordion data={data} />
      
      </div>
      </div>
      <Footer modifiedItem={modifiedItem} />
    </>
  );
};

export default HelpSupport;

// AutoplaySlider.js

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import Slider1 from '../assets/images/banner-1.png';
import Slider2 from '../assets/images/banner-2.png';
import Slider3 from '../assets/images/banner-3.png';
import Slider4 from '../assets/images/banner-4.png';
import Slider5 from '../assets/images/banner-5.png';

// Install the Autoplay module
SwiperCore.use([Autoplay]);

const images = [
  { src: Slider1, alt: 'Slide 1' },
  { src: Slider2, alt: 'Slide 2' },
  { src: Slider3, alt: 'Slide 3' },
  { src: Slider4, alt: 'Slide 4' },
  { src: Slider5, alt: 'Slide 5' },
];

const AutoplaySlider = () => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      loop={true} // Use boolean instead of string
      autoplay={{
        delay: 2500, 
        disableOnInteraction: false, 
      }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.src} alt={image.alt} loading="lazy" /> {/* Optional: lazy loading */}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AutoplaySlider;

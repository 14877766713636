import React, { useState } from 'react';
import "../assets/css/components/accordian.css";
import plusIcon from '../assets/images/icons/plus.png'
import minusIcon from '../assets/images/icons/minus.png'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { apiCall } from "../services/apiservices";
import "../pages/customer/HelpSupport";
import CustomSnackbar from '../components/snackBar';
// import SetLoadingScreen from "../components/SetLoader";
import { useUIContext } from "../context";


const AccordionItem = ({ title, isOpen, onToggle, children, text }) => {

    const [textareaValue, setTextareaValue] = useState('');
    const {loading, setloading} = useUIContext();
    const [submitModal,setSubmitModal]= useState(false);
    const [ticketId,setTicketId]= useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
    };
    

    const uploadSupportData = async (title,Description) =>{
        if (!Description.trim()) {
            showSnackbar("Description is required.", "error");
            return;
        }
        setloading(true);
        try{
            const payLoaData = {'title':title,'Description': Description};

            const response = await apiCall({ endpoint: "uploadHelpAndSupportData", method: 'post', payload: payLoaData });
    
            if (response.success) {
                setloading(false);
                setSubmitModal(true);
                setTicketId(response.message)
                setTextareaValue('');
            }else{
                setloading(false);
                setSubmitModal(false);
                console.log("Data Upload error");
            }
        }catch(error){
            setloading(false);
            console.log('Error on uploading data', error);
        }
    };

    function closeModalOnSubmit(){
        setSubmitModal(false)
        window.location.reload();
    }


    return (
        <>
        <div className="accordion-item">
            <div className="accordion-header" onClick={onToggle}>
                {title}

                <img src={plusIcon} alt="icon" className='PlusIcon'/>
                <img src={minusIcon} alt="icon" className='MinusIcon'/>

            </div>
            

            {isOpen && (
                <div className="accordion-body">
                    {text ? (
                        <div className="AccordianContent">
                                <textarea
                                    value={textareaValue}
                                    onChange={(e) => setTextareaValue(e.target.value)}
                                    placeholder='Issue / Description'
                                    rows="5"
                                />
                                <br />
                                <button type="submit" className='SubmitButton' onClick={() => uploadSupportData(title, textareaValue)} disabled={loading}>Submit</button>
                        </div>
                    ) : (
                        children
                    )}
                </div>
            )}

        <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={handleSnackbarClose} 
        message={snackbarMessage} 
        severity={snackbarSeverity} 
        />
        </div>
        <div className={`SubmitModalContain ${submitModal ? '' : 'd-none'}`}>
        <div className="SubmitModal">
            <TaskAltIcon className='SubmitIConCheck'/>
            <p>Your ticket has been generated.</p>
            <p className='TicketNo'>{ticketId}</p>
            <button onClick={()=>(closeModalOnSubmit())}>OK</button>
        </div>
        </div>
        </>
    );
};

const Accordion = ({ data }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        
        <div className="AccordianContain">
        <div className="accordion">
            {data.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    isOpen={openIndex === index}
                    onToggle={() => handleToggle(index)}
                    text={item.text}
                >
                    {item.children && item.children.length > 0 && (
                        <Accordion data={item.children} />
                    )}
                </AccordionItem>
            ))}
        </div>
        </div>

    );
};

export default Accordion;

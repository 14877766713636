import React, { useState, useEffect } from "react";
import "../../assets/css/admin/todorequest.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import ticketIcon from "../../assets/images/icons/ticket.png";
import RadioButtonGroup from "../../components/RadioGroup";
import Header from "../../components/Header";
import DashboardCards from "../../components/DashboardCard";
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Link, useParams } from "react-router-dom";
import { apiCall } from "../../services/apiservices";

const ToDoRequest = () => {
  const { getSelectedOption } = useParams();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(getSelectedOption || "thisMonth");
  const [totalCount, setTotalCount] = useState(0);
  const [inProcessCount, setInProcessCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);

  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "To-Do Request",
    link: `/admin/todorequest`,
  };

  const fetchData = async (option) => {
    const endpoint = `admin/getAdminToDashboardData?selectOption=${option}`;
    try {
      const res = await apiCall({ endpoint: endpoint, method: 'GET' });
      if (res.success) {
        setTotalCount(res.data.totalCount);
        setInProcessCount(res.data.inProcessCount);
        setResolvedCount(res.data.resolvedCount);
        let fetchedData = res.data.ticketData.map(item=>({
          ticketId: item.ticket_id,
          createdDate: item.create_date,
          categoryType: item.issue_type,
          remarks: item.comment,
          status: item.final_status,
          resolvedBy: item.resolved_by_name,
          crmRemarks: item.latest_crm_remarks
        }));
        setData(fetchedData);
      }
    } catch (error) {
      console.log('Error fetching data: ', error);
    }
  };

  const ticketData = [
    { count: `${totalCount}`, label: 'Total', className: '' },
    { count: `${inProcessCount}`, label: 'Pending', className: ' pending' },
    { count: `${resolvedCount}`, label: 'Resolved', className: ' resolve' },
  ];


  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    fetchData(selectedOption);
  }, [selectedOption]);

  return (
    <>
      <Header title="To-Do Request" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <DashboardCards title="Tickets Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} />

        <div className="TrackRequest mt-3">
          <div className="TrackRequestHeader">
            <RadioButtonGroup 
              options={["thisMonth", "lastMonth", "all"]} 
              initialOption={selectedOption} 
              onChange={handleOptionChange} 
            />
          </div>
        </div>

        {data.length > 0 ? data.map((item, index)=>(
          <div className="TrackDetails RequestDetails" key={index}>
            <span className={`CardCount ${item.status === 'In-Process' ? 'Colorblue' : item.status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>
              {item.status}
            </span>
            <div className="row gy-3">
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Ticket Id :  <span>{item.ticketId}</span></h4>
                </div>
              </div>
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Date :  <span>{item.createdDate}</span></h4>
                </div>
              </div>
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Category :  <span>{item.categoryType}</span></h4>
                </div>
              </div>

              {/* resolved by  */}
              {item.status === 'Resolved' && (<div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Resolved By :  <span>{item.resolvedBy}</span></h4>
                </div>
              </div>)}
              
              {item.status === 'Resolved' && (<div className="col-12">
                <div className="TrackMessageCard MessageCard mt-0">
                  <h4>Crm Remarks :</h4>
                  <p>{item.crmRemarks}</p>
                </div>
              </div>)}

              <div className="col-12">
                <div className="TrackMessageCard MessageCard">
                  <h4>Remarks :</h4>
                  <p>{item.remarks}</p>
                </div>
              </div>

              {item.status !== 'Resolved' && (
                <div className="col-12">
                  <div className="TrackMessageCard">
                    <Link to={`/admin/followup/${item.ticketId}`} state={{id: item.ticketId, cDate: item.createdDate, category: item.categoryType, cRemarks: item.remarks, ticketStatus: item.status}}>
                      <button className="RequestModalBtn">Action</button>
                    </Link>
                  </div>
                </div>
              )}
              <div className={`RequestModalContain ${open === false ? 'd-none' : ''}`}>
                <div className="RequestModal">
                  <h4 className="CardTitle">
                    Contact Details 
                    <button className="CloseBtn" onClick={() => setOpen(!open)}>
                      <CloseIcon />
                    </button>
                  </h4>
                  <ul>
                    <li>
                      <a href="mailto:cs.team@manthanitsolutions.com" target="_blank" className="Word_breack" rel="noreferrer">
                        <MailIcon /> cs.team@manthanitsolutions.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:18001370808">
                        <CallIcon /> 1800-137-0808
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )): ('')}
      </div>
      <Footer modifiedItem={modifiedItem} />
    </>
  );
};

export default ToDoRequest;

import React, { useState, useEffect } from 'react';
import "../../assets/css/customer/accountlink.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import Header from '../../components/Header';
import linkIcon from '../../assets/images/icons/link.png';
import addIcon from '../../assets/images/icons/plus.png';
import { apiCall } from "../../services/apiservices";
import CustomSnackbar from '../../components/snackBar';
import SetLoadingScreen from "../../components/SetLoader";
import { useUIContext } from "../../context";

const AccountLink = () => {
    const [open, setOpen] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [title, setTitle] = useState('');
    const [accountId, setAccountId] = useState('');
    const [password, setPassword] = useState('');
    const [accountdate, setaccountdata] = useState([]);
    const [portal, setPortal] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const {loading, setloading} = useUIContext();

    useEffect(() => {
        fetchData();
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleDeleteClick = (id) => {
        setSelectedId(id); // Set the selected ID for the specific card
        setOpen(true); // Open the modal
    };

    const handleAdd = async () => {
        setloading(true);
       try {
            if (portal.trim() === '') return showSnackbar("Please select a portal name", "error");
            if (title.trim() === '') return showSnackbar('Please fill Account Title', "error");
            if (accountId.trim() === '') return showSnackbar('Please fill Account ID', "error");
            if (password.trim() === '') return showSnackbar('Please fill password', "error");

            const accountData = { title, accountId, password, portal };

            let response = await apiCall({ endpoint: 'auth/addaccount', method: 'POST', payload: accountData });
            if (response.success) {
                setloading(false);
                fetchData();
                setActive(!active);
                setTitle('');
                setAccountId('');
                setPassword('');
                showSnackbar('Account added successfully!');
            } else if (response.status === 404) {
                setloading(false);
                showSnackbar('No data found for this account ID', "error");
            } else {
                setloading(false);
                showSnackbar('Error adding account', "error");
            }
        // }
       } catch (error) {
        setloading(false);
        console.log('error: ', error);
        
       }
    };

    const fetchData = async () => {
        setloading(true);
        try {
                let response = await apiCall({ endpoint: "auth/getaccountdata", method: 'GET'});
                if(response.success){
                    
                    let accountData = response.data.map(item => ({
                        id: item.id,
                        account_title: item.account_title,
                        account_id: item.account_id,
                        account_add_date: item.account_add_date,
                        source_name: item.source_name
                    }));
                    setloading(false);
                    setaccountdata(accountData);
                }
            // }
        } catch (error) {
            setloading(false);
            console.log(error);
            showSnackbar('Error fetching account data', "error");
        }
    };

    const deleteaccount = async (id) => {
        setloading(true);
        try {
            let response = await apiCall({ endpoint: `auth/deleteaccount/${id}`, method: 'DELETE'});
            if (response.success) {
                setloading(false);
                fetchData();
                setOpen(false);
                showSnackbar('Account deleted successfully!');
            }
        } catch (error) {
            setloading(false);
            console.log(error);
            showSnackbar('Error deleting account', "error");
        }
    };

    const modifiedItem = {
        index: 1,
        icon: linkIcon,
        label: "Link",
        link: "/accountlink",
    };

    return (
        <>
      <SetLoadingScreen loading={loading}/>
            <Header title="Account Link" arrowIcon={arrowIcon} />
            <div className="ContentContainer">
                <div className="AccountDropContain">
                    <div className="AccountCount">
                        <h4>Account : <span>{accountdate.length}</span></h4>
                    </div>
                    <div className='SelectContain'>
                        <select name="account" id="account" value={portal} onChange={(e) => setPortal(e.target.value)}>
                            <option value="">Select Portal</option>
                            <option value="smscounter">SMSCOUNTER</option>
                            <option value="easygosms">EASYGOSMS</option>
                            <option value="easygowp">EASYGOWP</option>
                        </select>
                        <button className='AccountAddBtn' onClick={() => setActive(!active)}><img src={addIcon} alt="icon" /></button>
                        <div className={`AddAccountModal ${active === false ? '' : 'Active'}`}>
                            <div className="AccountGroup">
                                <label htmlFor="title">Account Title</label>
                                <input type="text" name='title' value={title} placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="AccountGroup">
                                <label htmlFor="id">Account ID</label>
                                <input type="text" name='accountId' value={accountId} placeholder='Enter Id' onChange={(e) => setAccountId(e.target.value)} />
                            </div>
                            <div className="AccountGroup">
                                <label htmlFor="password">Account Password</label>
                                <input type="text" name='password' placeholder='Enter password' onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="AccountGroup">
                                <button className='add' onClick={handleAdd} disabled={loading}>Add</button>
                                <button className='delete' onClick={() => setActive(!active)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="AccountLinkCardContain">
                {accountdate.length > 0 ? accountdate.map((item, index) => (
                    <div className="TrackDetails AccountLink" key={item.id}>
                        <span className="CardCount">{index + 1}</span>
                        <div className="row gy-3">
                            <div className="col-12">
                                <div className="TrackMessageCard">
                                    <h4>Account Title : <span>{item.account_title}</span></h4>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="TrackMessageCard">
                                    <h4>Account Id : <span>{item.account_id}</span></h4>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="TrackMessageCard">
                                    <h4>Add Date : <span>{item.account_add_date}</span></h4>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="TrackMessageCard">
                                    <h4>Portal Name : <span>{item.source_name}</span></h4>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="TrackMessageCard">
                                    <button className="RequestModalBtn" onClick={() => handleDeleteClick(item.id)}>Delete</button>
                                </div>
                            </div>
                            {selectedId === item.id && open && (
                                <div className="RequestModalContain">
                                    <div className="RequestModal">
                                        <h4 className="CardTitle">Are you sure you want to delete this?</h4>
                                        <div className="AccountBtnContain">
                                            <button className='SureBtn' onClick={() => deleteaccount(item.id)} disabled={loading}>Sure</button>
                                            <button className='CancleBtn' onClick={() => setOpen(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )) : ('')}
                </div>
            </div>
            <Footer modifiedItem={modifiedItem} />

            {/* Snackbar Component */}
            <CustomSnackbar 
                open={snackbarOpen} 
                handleClose={handleSnackbarClose} 
                message={snackbarMessage} 
                severity={snackbarSeverity} 
            />
        </>
    );
};

export default AccountLink;

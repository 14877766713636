import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/employe/employefollowuphistory.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { apiCall } from '../../services/apiservices.jsx';


const EmployeFollowUpHistory = () => {
  const [data, setData] = useState([]);
  const { ticketId } = useParams();
  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "History",
    link: `/employe/followup/history/${ticketId}`,
  };
  useEffect(() => {
    const fetchData = async () => {
      let fetchedData = [];
      const endPoint = `employee/getEmployeeTicketHistory?ticketId=${ticketId}`;

      try {
        const res = await apiCall({ endpoint: endPoint, method: 'GET' });
        
        if (res.success) {
          fetchedData = res.data.ticketHistory || [];
        }
      } catch (error) {
        console.log("Error on fetching ticket Data", error);
      }

      setData(fetchedData);
    };

    fetchData();
  }, [ticketId]);
  const status = data.length > 0 ? data[0].status : 'In-Process';
      return (
        <>
        <Header title="Followup History" arrowIcon={arrowIcon}/>
          <div className="ContentContainer">
            
    
            <div className="FollowUpHeader mt-3 d-flex justify-content-between">
                <h4>Total Followup : <span>{data.length > 0 ? data.length: 0}</span> </h4>
                
                <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
            </div>
  
            {data.map((item, index)=>(
            <div className="TrackDetails  FollowUpHistory" key={index}>
            <span className="CardCount">{index + 1}</span>
   
                <div className="row gy-3 pt-3">
                    <div className="col-12 mt-0">
                        <div className="TrackMessageCard">
                          <h4 className="FollowupHistoryIssue">Category : <span>{item.issue_type}</span></h4>
                        </div>
                    </div>
                  <div className="col-6 pe-0">
                      <div className="TrackMessageCard">
                      <h4 >Ticket ID </h4>
                      <span>{ticketId}</span>
                      </div>
                  </div>
                    <div className="col-6 text-end ps-0">
                        <div className="TrackMessageCard">
                          <h4>Ticket Date </h4>
                          <span>{item.create_date} {item.create_time}</span>
                        </div>
                    </div>
                    <div className="col-6 pe-0">
                        <div className="TrackMessageCard">
                          <h4>Followed By </h4>
                          <span>{item.customer_id}</span>
                        </div>
                    </div>
                    <div className="col-6 text-end ps-0">
                        <div className="TrackMessageCard">
                          <h4>Followed Date </h4>
                          <span>{item.call_date}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4>Customer Remarks : </h4>
                            <textarea name="comment" id="comment" value={item.customer_remarks} placeholder="customer remark" readOnly> </textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4> Followup Remarks : </h4>
                            <textarea name="comment1" id="comment1" value={item.crm_remarks} placeholder="followup remark" readOnly></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                      <div className="TrackMessageCard  PlayVoiceContain">
                          <button className="PlayVoiceBtn RequestModalBtn"><PlayArrowIcon/> CRE Voice </button>
                      </div>
                    </div>
                </div>
            </div>
            ))}
          </div>
          <Footer modifiedItem={modifiedItem} />
        </>
      );
  };

export default EmployeFollowUpHistory
import React, { useEffect, useState } from "react";
import "../../assets/css/admin/followuphistory.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useParams } from "react-router-dom";
import { apiCall } from '../../services/apiservices.jsx';



const FollowUpHistory = () => {
  const {ticketId} = useParams();
  const [data, setData] = useState([]);
    const modifiedItem = {
      index: 1,
      icon: requestIcon,
      label: " History",
      link: `/admin/followup/history/${ticketId}`,
    };

    useEffect(()=>{
      const fetchData = async ()=>{
        let fetchedData = [];
        const endPoint = `admin/getAdminTicketHistory?ticketId=${ticketId}`;
      try{
        const res = await apiCall({endpoint: endPoint, method: 'GET'});
        console.log(res)
        if(res.success){
          fetchedData = res.data || [];
        }
      }catch(error){
        console.log('Error on fetching data', error);
      }
      setData(fetchedData);
      };

      fetchData();
    }, [ticketId]);

    let status = data.length > 0 ? data[0].status: 'In-Process';
    
    return (
      <>
      <Header title="Followup History" arrowIcon={arrowIcon} />
        <div className="ContentContainer">
          
  
          <div className="FollowUpHeader mt-3 d-flex justify-content-between">
              <h4>Total Followup : <span>{data.length > 0 ? data.length: 0}</span> </h4>
              
              <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
          </div>

          {data.map((item, index)=>(
            <div className="TrackDetails  FollowUpHistory" key={index}>
            <span className="CardCount">{index + 1}</span>
  
                <div className="row gy-3 pt-3">
                    <div className="col-12 mt-0">
                        <div className="TrackMessageCard">
                          <h4 className="FollowupHistoryIssue">Category : <span>{item.issue_type}</span></h4>
                        </div>
                    </div>
                  <div className="col-6 pe-0">
                      <div className="TrackMessageCard">
                      <h4 >Ticket ID </h4>
                      <span>{ticketId}</span>
                      </div>
                  </div>
                    <div className="col-6 text-end ps-0">
                        <div className="TrackMessageCard">
                          <h4>Ticket Date </h4>
                          <span>{item.create_date} {item.create_time}</span>
                        </div>
                    </div>
                    <div className="col-6 pe-0">
                        <div className="TrackMessageCard">
                          <h4>Followed By </h4>
                          <span>{item.customer_id}</span>
                        </div>
                    </div>
                    <div className="col-6 text-end ps-0">
                        <div className="TrackMessageCard">
                          <h4>Followed Date </h4>
                          <span>{item.call_date}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4>Customer Remarks : </h4>
                            <textarea name="comment" id="comment" value={item.customer_remarks} readOnly> </textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="TrackMessageCard FallowCard">
                            <h4> Followup Remarks : </h4>
                            <textarea name="comment1" id="comment1" value={item.crm_remarks} readOnly></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                      <div className="TrackMessageCard  PlayVoiceContain">
                          <button className="PlayVoiceBtn RequestModalBtn"><PlayArrowIcon/> CRE Voice </button>
                      </div>
                    </div>
                </div>
            </div>
          ))}
        </div>
        <Footer modifiedItem={modifiedItem} />
      </>
    );
};
export default FollowUpHistory
import React, { useState } from "react";
import "../assets/css/login.css";
import Logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { SignUpUser,verifyOTP } from "../services/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from 'react-router-dom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CustomSnackbar from '../components/snackBar';
import SetLoadingScreen from "../components/SetLoader";
import { useUIContext } from "../context";

const Process = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const formData = location.state;
    // const [userType, setUserType] = useState(null);
    // const [redirectToHome, setRedirectToHome] = useState(false);
    const [submitModal, setSubmitModal] = useState(false)
    const [responseMessage, setResponseMessage] = useState("");
    const [mobileOtpVisible, setOtpInputVisible ] = useState(false);
    const [submitButtonCondition, setSubmitButtonCondition ] = useState(false);
    const [credentials, setCredentials] = useState({
      mobile: "",
      mobileOtp: "",
      email: "",
    });
    const [errors, setErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const {loading, setloading} = useUIContext();

    const showSnackbar = (message, severity = 'success') => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
      
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') return;
      setSnackbarOpen(false);
    };
  

    const validateForm = () => {
      let formErrors = {};
    
      // Check if email is empty or invalid
      if (!credentials.email.trim()) {
        formErrors.email = "Email is required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(credentials.email.trim())) {
        formErrors.email = "Invalid email format";
      }
    
      // Check if mobile number is empty
      if (!credentials.mobile.trim()) {
        formErrors.mobile = "Mobile number is required";
      }
    
      // Check if mobile OTP is required and empty
      if (mobileOtpVisible && !credentials.mobileOtp.trim()) {
        formErrors.mobileOtp = "Mobile OTP is required";
      }
    
      setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
    };
    
    const handleChanges = (e) => {
      const { name, value } = e.target;
      setCredentials((prevState) => ({ ...prevState, [name]: value }));
    };

    const setMobileOtpVisible = async () => {
      let formErrors = {};
      setloading(true);
      // Check if the mobile number is empty or not exactly 10 digits
      if (!credentials.mobile.trim()) {
        formErrors.mobile = "Mobile number is required";
      } else if (!/^\d{10}$/.test(credentials.mobile.trim())) {
        formErrors.mobile = "Mobile number must be 10 digits";
      } else {
        try{
          const dataPayload = { formData, credentials };
    
        const response = await verifyOTP({ payload: dataPayload });
        if (response.success) {
          setloading(false);
          setOtpInputVisible(true);
          setSubmitButtonCondition(true);
        }
        }catch(error){
          setloading(false);
          console.log('Error on generating OTP: ', error);
        }
      }
      setloading(false);
      setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
    };
    

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!validateForm()) {
        return;
      }
      setloading(true);
      try {

        let payloadData = {formData,credentials}
        const response = await SignUpUser({ payload: payloadData });

        if(response.success){
          setloading(false);
          setSubmitModal(true);
          setResponseMessage(response.message);
        }else{
          showSnackbar(response.message, 'error');
        }

      } catch (error) {
        setloading(false);
        toast.error(error.message);
      }
    };

    function closeModalOnSubmit(){
      setSubmitModal(false)
    }

    return (
      <>
      <SetLoadingScreen loading={loading}/>
        <div className="LoginContain">
          <div className="LoginLogo">
            <img src={Logo} alt="img" />
          </div>
          <div className="LoginCard">
            <div className="LoginTitle">
              <h4>Sign Up</h4>
              <p>Welcome To Manthan App.</p>
              <p>Manthan support team is just a tap away!</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="LoginGroup">
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={credentials.email}
                  placeholder="Enter Email Address"
                  onChange={handleChanges}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="LoginGroup">
                <label htmlFor="mobile">Mobile Number</label>
                <div className="ProcessGroup">
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={credentials.mobile}
                    placeholder="Enter Mobile Number"
                    onChange={handleChanges}
                  />
                  <button
                    type="button"
                    className="GetOtpBtn"
                    onClick={setMobileOtpVisible}
                  >
                    Get OTP
                  </button>
                </div>
                {errors.mobile && <span className="error">{errors.mobile}</span>}
              </div>
              {mobileOtpVisible && (
                <div className="LoginGroup">
                  <label htmlFor="mobileOtp">Mobile OTP</label>
                  <input
                    type="text"
                    id="mobileOtp"
                    name="mobileOtp"
                    value={credentials.mobileOtp}
                    placeholder="Enter Mobile OTP"
                    onChange={handleChanges}
                  />
                  {errors.mobileOtp && <span className="error">{errors.mobileOtp}</span>}
                </div>
              )}

              {submitButtonCondition && (
              <div className="SignUpGroup SignUpBtn">
                <button type="submit" disabled={loading}>Submit</button>
              </div>
              )}
              <div className="SignUpDivider">
                <span>OR</span>
              </div>
              <div className="LoginGroup signUp">
                <p>
                  Already have an account? <Link to="/">Login</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="LoginFooter">
          <p>
            POWERED BY: <span>MANTHAN IT SOLUTIONS</span>
          </p>
        </div>
        <ToastContainer />

        <div className={`SubmitModalContain ${submitModal ? '' : 'd-none'}`}>
            <div className="SubmitModal">
                <TaskAltIcon className='SubmitIConCheck'/>
                <p>{responseMessage}.</p>
                <button onClick={() => {closeModalOnSubmit();
                  navigate(`/`);
                }}>OK</button>
          </div>
        </div>

        <CustomSnackbar 
            open={snackbarOpen} 
            handleClose={handleSnackbarClose} 
            message={snackbarMessage} 
            severity={snackbarSeverity} 
          />
      </>
    );
};

export default Process;

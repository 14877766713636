import * as React from 'react';
import "../assets/css/components/employetabs.css";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useUIContext } from '../context';
import DashboardCards from './DashboardCard';
import targetIcon from '../assets/images/icons/target.png';
import DevelopmentTask from './DevelopmentTask';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EmployeTabs() {
  const [value, setValue] = React.useState(0);
  const { totalTarget, achievedTarget, pendingTarget, setAchievedTarget, setPendingTarget, setTotalTarget } = useUIContext();
  
  const Target = 200000;
  const Achieved = 170000;
  const Pending = Target - Achieved;

  React.useEffect(() => {
    if (achievedTarget !== Achieved) setAchievedTarget(Achieved);
    if (pendingTarget !== Pending) setPendingTarget(Pending);
    if (totalTarget !== Target) setTotalTarget(Target);
  }, [achievedTarget, pendingTarget, totalTarget, setAchievedTarget, setPendingTarget, setTotalTarget, Achieved, Pending, Target]);

  const ticketData = [
    { count: totalTarget, label: 'Target', className: '' },
    { count: achievedTarget, label: 'Achieved', className: 'resolve' },
    { count: pendingTarget, label: 'Pending', className: 'pending' },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'orangered',
            },
          }}
        >
          <Tab label="Sales" {...a11yProps(0)} sx={{ color: value === 0 ? 'orangered' : 'text.secondary', '&.Mui-selected': { color: 'orangered' } }}/>
          <Tab label="Support" {...a11yProps(1)} sx={{ color: value === 1 ? 'orangered' : 'text.secondary', '&.Mui-selected': { color: 'orangered' } }}/>
          <Tab label="Development" {...a11yProps(2)} sx={{ color: value === 2 ? 'orangered' : 'text.secondary', '&.Mui-selected': { color: 'orangered' } }}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} className='TabsPannel'>
        <div className="TargetFormGroup">
          <label htmlFor="name">Service Type</label>
          <select name="type" id="type">
            <option value="sms">SMS</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
        </div>
        <div className="TargetFormGroup">
          <textarea name="remarks" id="remarks" placeholder='Enter Your Remarks...' rows={5}></textarea>
        </div>
        <div className="TargetFormGroup mt-4 text-center">
          <button type='submit'>Submit</button>
          <Link to='/employe/graph'><button type='button' className='ms-2'>View Graph</button></Link>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} className='TabsPannel'>
        <DashboardCards title="Monthly Ticket Target" invoiceIcon={targetIcon} ticketData={ticketData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} className='TabsPannel'>
        <DevelopmentTask />
      </CustomTabPanel>
    </Box>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import "../../assets/css/customer/bot.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
// import SendIcon from "../../assets/images/icons/send.png";
import SendIcon from "../../assets/images/icons/send1.png";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import Header from '../../components/Header';
import { apiCall } from "../../services/apiservices";
import getCurrentIST from '../../services/currentDateTime';
import { useUIContext } from "../../context";
import ArrowDown from '../../assets/images/icons/arrow-down.png';
import  PdfIcon from '../../assets/images/icons/pdf.png';
import ExcelIcon from '../../assets/images/icons/excel.png';
import Imageicon from '../../assets/images/icons/image-icon.png';
import Videoicon from '../../assets/images/icons/video_icon.png';
import SetLoadingScreen from "../../components/SetLoader";
import CloseIcon from '@mui/icons-material/Close';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [openFilePreview, setOpenFilePreview] = useState(false);
  const chatWindowRef = useRef(null);
  const location = useLocation();
  const chatId = location.state?.id;
  const mediaRecorderRef = useRef(null);
  const socketRef = useRef(null);
  const senderId = JSON.parse(localStorage.getItem('user-cred')).user.customer_id;
  const {loading, setloading} = useUIContext();
  const currentTime = getCurrentIST();
  const [isTyping, setIsTyping] = useState(false);
  const {typingStatus, setTypingStatus,onlineStatus,setOnlineStatus} = useUIContext();
  const [msgReadStatus, setMsgReadStatus] = useState('0');
  
  const [openmodel, setopenmodel] = useState(false);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  // const [page, setPage] = useState(1) 
  const [isLoading, setIsLoading] = useState(false);
  const [downloadBtnValue, setDownloadBtnValue] = useState('') 
  const fileInputRef= useRef(null)

  const navigate = useNavigate();
  const employeeName = location.state?.employeeName || 'Chat';
  
  
const convertUrlTobase64 =async (url) => {
  const endpoint = `chat/convertUrlTobase64`;
      const payload= {'url': url}        
      let response = await apiCall({endpoint: endpoint, method: 'POST', payload});
      return response.base64
}
const downloadBtn= async(url)=>{
  // console.log('url: ', url);
  let urlBase64String = await convertUrlTobase64(url)
  setDownloadBtnValue(urlBase64String);  

    // Create an anchor element
    const link = document.createElement('a');
    link.href = urlBase64String; 
    link.download = 'Document'; 
    document.body.appendChild(link); 
    link.click(); 
    document.body.removeChild(link); 
  
}


const openModal =async (url,media_type) => {
  setIsLoading(true)      

  let urlBase64String=await convertUrlTobase64(url)
  // let urlBase64String='
  if (urlBase64String) {
    setIsLoading(false)
    setMediaUrl(urlBase64String);
    setMediaType(media_type);
    setopenmodel(true);  
  } else {
    setIsLoading(false)
    toast.error("Fail to open document");
    console.error("Failed to convert URL to Base64, modal will not open.");    
  }
};



const closeModal = () => {
  setopenmodel(false);  
  setMediaUrl("");  
};
  
  useEffect(() => {
    if (!chatId) {
      navigate("/employe/chatlist");
      return;
    }

    const fetchConversation = async () => {
      try {
        setIsLoading(true);
        const endpoint = `chat/getChatData?receiverId=${chatId}`;
        const response = await apiCall({ endpoint: endpoint, method: 'GET' });
        const groupedMessages = response.data.map((dayData) => ({
          date: dayData.date,
          chats: dayData.chats
        }));
        setMessages(groupedMessages);
      } catch (error) {
        console.log('Error on fetching chat data: ', error);
      }
      finally{
        setIsLoading(false);
      }
    };

    fetchConversation();

    // mark messages as read
    const markMessageRead = async() =>{
      try{
        const endpoint = `chat/markMessagesAsRead`;
        const payload= {
          'senderId': senderId,
          'receiverId': chatId
        }
        await apiCall({endpoint: endpoint, method: 'POST', payload});
      }catch(error){
        console.log('Failed to read messages: ', error);
      }
    }

    markMessageRead();

    // socket.io initialization
    socketRef.current = io('https://manthanapp.in');
    // socketRef.current = io('http://localhost:8001');

    socketRef.current.on('connect', () => {
      console.log('Connected to Socket.IO server');
      socketRef.current.emit('connect_user', senderId);
    });
    
    // user status main code
    socketRef.current.on('update_user_status', ({ userId, status, users }) => {
      
      if (users && users.hasOwnProperty(senderId) && users.hasOwnProperty(chatId)) {
        // console.log("Both 'dheeraj' and 'rohit' are present in users");
        setOnlineStatus('Online');
        setMsgReadStatus('1');
        fetchConversation();
      }else{
        setOnlineStatus('Offline')
        setMsgReadStatus('0');
      }
    });

    // SUBSTITUTE CODE
    socketRef.current.on('receive_message', (message) => {
      if (message.sender === chatId) {
        // console.log('message:', message)
        setMessages((prevMessages) => {
          const hasTodayDate = prevMessages.some(msg => msg.date === currentTime.todayDate);
    
          return [
            ...prevMessages,
            ...(hasTodayDate ? [] : [{ date: currentTime.todayDate, chats: [] }]),
            {
              chats: [{
                message: message.content,
                sender_id: message.sender,
                timestamp: currentTime.currTime,
                read_status: true,
                media_url: message.media_url || null,
                media_type: message.media_type || null,
                media_name: message.media_name || null
                
              }]
            },
          ];
        });
      }
    });
    
    socketRef.current.on('typing_status', ({ sender, isTyping }) => {
      if (sender === chatId) setTypingStatus(isTyping ? 'Typing...' : '');
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, [chatId]);


  // function to send messages and file
  const sendMessage = async () => {
    setloading(true)

    // console.log('file:', file)
    const formData = new FormData();
    formData.append("recipientId", chatId);
    formData.append("message", input);
    formData.append("readStatus", msgReadStatus);
  
    if (file) {
      formData.append('file', file);      
    }
  
    if (input.trim() || file) {
      try {
        setIsLoading(true)
        const endpoint = `chat/storeMessages`;
        const response = await apiCall({ endpoint, method: 'POST', payload: formData });
        // console.log('response: ', response);
        
        if(!response.success){
          setIsLoading(false)
          setOpenFilePreview(false)
          toast.error("Fail to send message");
          return;
        }
        let mediaUrl = response?.media_url || null;
        let mediaName = response?.media_name || null;
        let mediaType = response?.media_type || null;
        // console.log('mediaUrl: ', mediaUrl);
        // console.log('mediaName: ', mediaName);
        // console.log('mediaType: ', mediaType);
        

        // if(mediaUrl){
        //   media_name = mediaUrl.split('/').pop(); // Gets the file name, "img3.jpg"
        //   media_type = mediaUrl.split('.').pop();        
        // }else{
        //   media_name=null
        //   media_type=null
        // }
        
        const messageToSend = {
          sender: senderId,
          recipient: chatId,
          content: input,
          media_url: mediaUrl,
          media_name: mediaName,
          media_type: mediaType
          
        };
        socketRef.current.emit('send_message', messageToSend);

        const hasTodayDate = messages.some(msg => msg.date === currentTime.todayDate);
        setMessages((prevMessages) => [
          ...prevMessages,
          ...(!hasTodayDate ? [{ date: currentTime.todayDate, chats: [] }] : []),
          { chats: [{ message: input, sender_id: senderId, timestamp: currentTime.currTime, read_status: msgReadStatus, media_url: mediaUrl, media_name: mediaName, media_type: mediaType}] }
        ]);
        setInput('');
        setFile(null);
        setOpenFilePreview(false);
        fileInputRef.current.value = '';
      } catch (error) {
        setIsLoading(false);
        setOpenFilePreview(false)
        console.log('Error on sending message:', error);
        toast.error("Network error: Failed to send message");
      } finally{
        setIsLoading(false)
        setloading(false);
        setOpenFilePreview(false)
      }
    }
  };
  
  const handleSendMessage = (e) => {
    e.preventDefault();
    sendMessage();
  };

  // handle input change
  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (!isTyping) {
      setIsTyping(true);
      socketRef.current.emit('typing', { recipient: chatId, isTyping: true });
      setTimeout(() => {
        setIsTyping(false);
        socketRef.current.emit('typing', { recipient: chatId, isTyping: false });
      }, 1000);
    }
  };

  const handleUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setOpenFilePreview(true);
    }
  };
 


  const handleVoiceRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.start();

      const audioChunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks);
        setAudioBlob(audioBlob);
      };

      setIsRecording(true);
    }
  };


  const Message = ({ text, sender, timestamp, read, media_url, media_type, media_name }) => (
    <div className={sender === senderId ? 'BotUserMessage' : 'BotMessage'}>
        {text && <div className='BotMessageText Word_breack'>{text}</div>}
        {/* {console.log('media_type:',media_type)} */}
        {media_url && media_type && (
          <div className="file-preview">
              {/* Handle image files */}
              {media_type.match(/(jpeg|jpg|gif|png)$/) && (
                  <div className="media-item">
                      <div className="preview-container preview-container-image medio_icon"  >
                          {/* <img src={media_url} alt="preview" style={{ width: '100%' }} /> */}
                          <img src={Imageicon} alt="preview"   onClick={() => openModal(media_url,media_type)}/>
                      </div>
                      <div className="download-btn">
                      <p>{media_name}</p>
                      {/* {<a href={downloadBtnValue} target='_blank' download={media_name} rel="noopener  noreferrer" onClick={() => downloadBtn(media_url)}><img src={ArrowDown} alt="arrow"  /></a>} */}
                      
                        <img src={ArrowDown} alt="arrow" onClick={() => downloadBtn(media_url)} />                                                            
                      </div>
                  </div>
              )}

              {/* Handle video files */}
              {media_type.match(/(mp4|webm|ogg)$/) && (
                  <div className="media-item">
                      <div className="preview-container preview-container-video medio_icon">
                          {/* <VideoPlayer src = {media_url} /> */}
                          {/* <PlayCircleOutlineIcon style={{width:'100px'}} /> */}
                          <img src={Videoicon} alt="preview" onClick={() => openModal(media_url,media_type)}/>

                      </div>
                      <div className="download-btn">
                      <p>{media_name}</p>
                      {/* {<a href={downloadBtnValue} target='_blank' download={media_name} rel="noopener  noreferrer" onClick={() => downloadBtn(media_url)}><img src={ArrowDown} alt="arrow"  /></a>} */}
 
                      <img src={ArrowDown} alt="arrow" onClick={() => downloadBtn(media_url)} />
                      </div>
                  </div>
              )}

              {/* Handle PDF files */}
              {media_type.match(/pdf$/) && (
                  <div className="media-item">
                      <div className="preview-container preview-container-pdf medio_icon">
                          <img src={PdfIcon} alt="pdficon" className='bot_media_icon'  />
                      </div>
                      <div className="download-btn">
                        <p>{media_name}</p>
                        {/* {<a href={downloadBtnValue} target='_blank' download={media_name} rel="noopener  noreferrer" onClick={() => downloadBtn(media_url)}><img src={ArrowDown} alt="arrow"  /></a>} */}
                        <img src={ArrowDown} alt="arrow" onClick={() => downloadBtn(media_url)} />
                      </div>
                  </div>
              )}

              {/* Handle Excel files */}
              {media_type.match(/(xls|xlsx|csv)$/) && (
                  <div className="media-item">
                      <div className="preview-container preview-container-pdf medio_icon">
                          <img src={ExcelIcon} alt="excelIcon" className='bot_media_icon' />
                      </div>
                      <div className="download-btn">
                      <p>{media_name}</p>
                      <div >
                      {/* {<a href={downloadBtnValue} target='_blank' download={media_name} rel="noopener  noreferrer" onClick={() => downloadBtn(media_url)}><img src={ArrowDown} alt="arrow"  /></a>} */}
                      <img src={ArrowDown} alt="arrow" onClick={() => downloadBtn(media_url)} />

                      </div>                      
                      </div>
                  </div>
              )}

              {/* Default download link for other file types */}
              {/* {!media_url.match(/\.(jpeg|jpg|gif|png|mp4|webm|ogg|pdf|xls|xlsx)$/) && (
                  <div className="media-item">
                      <p>{media_url.split('/').pop()}</p>
                      <div className="download-btn">
                        {<a href={media_url} target='_blank' download={media_url.split('/').pop()} rel="noopener noreferrer"><img src={ArrowDown} alt="arrow" /></a>}
                      </div>
                  </div>
              )} */}
          </div>
          

        )}

        <div className='BotMessageFooter'>
            <span className='BotTimestamp'>{timestamp}</span>
            {sender === senderId && read === '1' ? (
                <div className='BotCheckIcons'>
                    <DoneAllIcon className='BotCheckIcon' />
                </div>
            ) : sender === senderId && read === '0' ? (
                <DoneIcon className='BotCheckIcons' />
            ) : null}
        </div>
    </div>
    
  );

  // video render component
  const VideoPlayer = ({ src }) => (
    <video width="100%" controls>
      <source src={src} type="video/mp4" />
    </video>
  );
const [scrollBottomStatus, setScrollBottomStatus] = useState(true)
useEffect(() => {

    if (chatWindowRef.current && scrollBottomStatus) {            
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);


  return (
    <>
    <SetLoadingScreen loading={isLoading}/>
      <Header title={employeeName} arrowIcon={arrowIcon} />
      <div className="ContentContainer BotChatApp">
      <div ref={chatWindowRef} className='BotChatWindow'>
        {messages.map((dayData, dateIndex) => (
          <React.Fragment key={dateIndex}>
            { dayData.date ? (
              <div className="date-header">
                <span>{dayData.date}</span>
              </div>) : ''}
            {(dayData.chats || []).map((msg, msgIndex) => (
              <Message
                key={msg.id || msgIndex}
                text={msg.message}
                sender={msg.sender_id}
                timestamp={msg.timestamp}
                read={msg.read_status}
                media_url={msg.media_url}
                media_type={msg.media_type}
                media_name={msg.media_name}
              />
            ))}
          </React.Fragment>
        ))}
      </div>

        <form onSubmit={handleSendMessage} className='BotMessageInput'>
          <label htmlFor="file-upload" className='BotUploadLabel'>
            <AttachFileIcon className='BotIcon' />
          </label>
          <input
            id="file-upload"
            type="file"
            className='BotFileInput'
            onChange={handleUpload}
            ref={fileInputRef}            
          />
          <MicIcon
            className='BotIcon'
            onClick={handleVoiceRecord}
            style={{ color: isRecording ? 'red' : 'black' }}
          />
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            className='BotInput'
          />
          <button type="submit" className='BotSendButton'>
            <img src={SendIcon} alt="icon" className='BotSendIcon' />
          </button>
        </form>
      </div>

      <div className={`chat_modal ${openFilePreview ? 'open' : ''}`}>
      <div className="chat_modal_overlay" onClick={() => setOpenFilePreview(false)}>
      <div className="chat_modal_content">
        <div className="chat_modal_header">
          <h3>File Preview</h3>
          <CloseIcon onClick={() => setOpenFilePreview(false)}/>
        </div>
        <div className="chat_modal_body">
          {file && (file.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(file)} alt="Preview" />
          ) : (
            <p>{file.name}</p>
          ))}
        </div>
        <div className="chat_modal_footer">
          {/* <button onClick={() => setOpenFilePreview(false)} className="cancel-btn">Cancel</button> */}
          <button
            onClick={sendMessage}
            className={`send-btn ${loading ? 'disabled' : ''}`}
            disabled={loading}
          >
            Send
          </button>
        </div>
      </div>
      </div>
    </div>

    {openmodel && (
      
        <div className="chat_modal_overlay2">
          <div className=" chat_modal_content2">
          <div className="chat_modal_header2">
            <h3>File Preview</h3>
            <CloseIcon onClick={closeModal}/>
          </div>

          <div className="chat_modal_body2">
            {/* Display the media based on its type */}
            {mediaType.match(/(jpeg|jpg|png)$/) && (
              <img src={mediaUrl} alt="preview"  />
            )}

            {mediaType.match(/(mp4|webm|ogg)$/) && (
              <video controls >
                <source src={mediaUrl} type={`${mediaType}`} />                
                Your browser does not support the video tag.
              </video> 
            )}

            {mediaType.match(/(pdf|csv|xls|xlsx)$/) && (
              <iframe src={mediaUrl}  title="PDF Preview"></iframe>
            )}
            {/* {mediaType.match(/(pdf|csv|xls|xlsx)$/) && (
              <embed src={mediaUrl}  title="PDF Preview" type={`${mediaType}`}/>
            )} */}

          </div>

            {/* <div className="chat_modal_footer2">
              <button className="cancel-btn" onClick={closeModal}>Close</button>
            </div> */}

          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Chat;

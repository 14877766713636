import React from "react";
import '../../src/assets/css/components/setLoader.css'

const SetLoadingScreen = ({loading})=>{
    return (
        <div className={`loader ${loading ? '' : 'd-none'}`}>
            <div className="spinner"></div>
            <p>Loading...</p>
        </div>
    )
}


export default SetLoadingScreen; 
import * as React from 'react';
import "../assets/css/components/developmenttask.css";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';

const accordionData = [
  { panel: 'panel1', checkboxLabel: 'Task Name 1', date: '17-10-2024'},
  { panel: 'panel2', checkboxLabel: 'Task Name 2', date: '17-10-2024'},
  { panel: 'panel3', checkboxLabel: 'Task Name 3', date: '17-10-2024'},
  { panel: 'panel4', checkboxLabel: 'Task Name 4', date: '17-10-2024'},
];

export default function DevelopmentTask() {
  const [expanded, setExpanded] = React.useState(false);
  const [checked, setChecked] = React.useState({});
const handleCheckboxChange = (panel) => (event) => {
    const isChecked = event.target.checked;
    setChecked({ ...checked, [panel]: isChecked });
  
    if (isChecked) {
      setExpanded(panel);
    } else {
      // Close the accordion if the checkbox is unchecked
      setExpanded(false);
    }
  };
  

  const handleChange = (panel) => (event, isExpanded) => {
    if (checked[panel]) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  return (
    <>
      {accordionData.map(({ panel, checkboxLabel, date }) => (
        <Accordion key={panel} expanded={expanded === panel} onChange={handleChange(panel)} className='DevelopmentTaskAccordian'>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${panel}-content`}
            id={`${panel}-header`}
          >
            <Typography sx={{ width: '15%', flexShrink: 0 }}>
              <Checkbox checked={checked[panel] || false} onChange={handleCheckboxChange(panel)} />
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{checkboxLabel}</Typography>
            <Typography sx={{ color: 'text.secondary', textAlign: 'end', marginLeft: 'auto', marginRight: '10px' }}>{date}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="TargetFormGroup">
              <textarea name="remarks" id={`remarks-${panel}`} placeholder='Enter Your Remarks...' rows={5}></textarea>
            </div>
            <div className="TargetFormGroup mt-4 text-center">
              <button type='submit'>Submit</button>
              <Link><button type='button' className='ms-2'>View Status</button></Link>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
